@tailwind base;

.table {
	@apply w-full;
}
.table th {
	@apply border-b text-left bg-gray-100 p-1;
}
.table tr {
	@apply  hover:bg-gray-200
}
.table td {
	@apply border-b p-1
}
.table .options {
	@apply flex items-center space-x-1
}
.edit {
	@apply text-lg text-yellow-600 hover:scale-105;
}
.inactive {
	@apply text-lg text-gray-400 cursor-not-allowed;
}
.delete {
	@apply text-lg text-red-500 hover:scale-105
}	
.lock {
	@apply text-lg text-blue-500 hover:scale-105
}	
.submenu {
	@apply bg-gray-700 py-1 px-2 rounded text-gray-200 hover:opacity-90 active:scale-95
}
.report {
	@apply w-full
}
.report th {
	@apply align-top text-left;
}
.report td {
	@apply text-right align-top
}

.report_2 {
	@apply w-full text-xs
}
.report_2 th {
	@apply text-center border border-gray-600;
}
.report_2 td {
	@apply p-1 border border-gray-600
}
.details {
	@apply w-11/12 mx-auto border;
}
.details th {
	@apply text-right pr-2 border-b border-gray-300 py-2 bg-gray-200
}
.details td {
	@apply text-left pl-2 border-b border-gray-300
}

.main_menu li {
	@apply relative;
}

.main_menu li:hover .sub_menu {
	@apply block
}

.sub_menu {
	@apply w-[150%] hidden absolute top-[100%] left-0 rounded-b shadow-lg
}
.sub_menu a {
	@apply p-1 text-white bg-blue-400 border-b border-blue-200 block px-3 hover:bg-blue-500
}

@tailwind components;
@tailwind utilities;